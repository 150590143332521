<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Сгруппировать_4918" data-name="Сгруппировать 4918" transform="translate(-348 -1569)">
      <g id="Сгруппировать_4801" data-name="Сгруппировать 4801" transform="translate(9 1105)">
        <path id="Контур_4463" data-name="Контур 4463" d="M828.041-2444l3.043,3.29-3.043,3.263" transform="translate(-479.875 2914.78)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
        <g id="Эллипс_781" data-name="Эллипс 781" transform="translate(339 464)" fill="none" stroke="#fff" stroke-width="2">
          <circle cx="10" cy="10" r="10" stroke="none"/>
          <circle cx="10" cy="10" r="9" fill="none"/>
        </g>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'IconRightOrange'
}
</script>

<style scoped>

</style>
