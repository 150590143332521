<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <IconCreditCards class="mb-1" />
    <label for="cardHolderName">{{ $t("common.cardName") }}</label>
    <v-text-field
      id="cardHolderName"
      v-model="cardHolderName"
      :rules="creditCardRules.cardHolderName"
      x-large
      solo
      flat
      outlined
      type="text"
      class="mt-2 rounded-lg"
    />

    <!-- START Card-number ------------------------>
    <label for="firstCardNumberValue">{{ $t("common.cardNumber") }}</label>
    <div class="d-flex justify-space-between  no-input-arrow mt-2">
      <FixedLengthInput
        id="firstCardNumberValue"
        :fixedLength="4"
        v-model="cardNumber.firstValue"
        :rules="creditCardRules.cardNumber"
        placeholder="0000"
        type="number"
        class="mr-1 center-input"
        :next-input="$refs.secondValue"
        outlined
        solo
        flat
      />
      <FixedLengthInput
        ref="secondValue"
        :fixedLength="4"
        v-model="cardNumber.secondValue"
        :rules="creditCardRules.cardNumber"
        placeholder="0000"
        type="tel"
        class="mr-1 center-input"
        :next-input="$refs.thirdValue"
        outlined
        solo
        flat
      />
      <FixedLengthInput
        ref="thirdValue"
        :fixedLength="4"
        v-model="cardNumber.thirdValue"
        :rules="creditCardRules.cardNumber"
        placeholder="0000"
        type="tel"
        class="mr-1 center-input"
        :next-input="$refs.fourthValue"
        outlined
        solo
        flat
      />
      <FixedLengthInput
        ref="fourthValue"
        :min-length="2"
        :max-length="4"
        v-model="cardNumber.fourthValue"
        :rules="creditCardRules.cardNumber4"
        placeholder="0000"
        type="tel"
        class="mr-1 center-input"
        outlined
        solo
        flat
      />
    </div>
    <!-- END Card-number -------------------------->

    <label for="expirationMonth">{{ $t("menu.main.items.expirationDate") }}</label>
    <div class="d-flex pr-15 mr-15 mt-2">
      <v-select
        id="expirationMonth"
        :items="monthList"
        :rules="[$rules.required]"
        placeholder="MM"
        v-model="expirationMonth"
        solo
        flat
        class="rounded-lg"
        outlined
      />
      <label class="mx-3 mt-5 --c-black">/</label>
      <v-select
        :items="yearList"
        v-model="expirationYear"
        :rules="[$rules.required]"
        placeholder="YYYY"
        solo
        flat
        class="rounded-lg"
        outlined
      />
    </div>

    <label for="securityCode">{{ $t("common.securityCode") }}</label>
    <div class="d-flex mr-4">
      <FixedLengthInput
        id="securityCode"
        :minLength="3"
        :maxLength="4"
        v-model="securityCode"
        :rules="creditCardRules.securityCode"
        placeholder="000"
        type="tel"
        class="mt-2 col-4 center-input fixed-width--medium no-input-arrow"
        outlined
        solo
        flat
      />
    </div>

    <div class="text-center">
      <v-btn
        elevation="0"
        @click="submitForm"
        :disabled="!isCcAvailable"
        x-large
        rounded
        class="w-100 mb-10"
        color="bg-orange--grad text-heading --white"
      >
        <span class="mx-auto">
          {{ $t("buttons.confirm") }}
        </span>
        <IconRight/>
      </v-btn>
      <div v-if="ccMaintenanceMessage" class="--c-red mt-2">
        <format-span :value="ccMaintenanceMessage" />
      </div>
      <CommonModal
        :visible="isSubmitted"
        :is-persistent="true"
        @close="$router.push(afterSubmitLink)"
      >
        <template slot-scope="props">
          <h3 class="mb-5 text-title --c-prm">
            {{ $t('memberInfo.haveChangedCreditCardInfo') }}
          </h3>
          <v-main class="mx-3">
            <v-btn
              elevation="0"
              color="btn-grad--prm"
              class="text-medium --w-bold"
              @click="props.close()"
              block
              x-large
              rounded
            >
              {{ $t('buttons.backToTop') }}
            </v-btn>
          </v-main>
        </template>
      </CommonModal>
    </div>
  </v-form>
</template>

<script>
import CommonModal from '../../../components/Modal/Common'
import FixedLengthInput from '../../../components/FixedLengthInput'
import IconRight from '../../../components/icons/IconRight'
import {
  urlMixin
} from '@/mixins/url.mixin'
import { getMonthList, getNextYearsFromCurrent } from '@/utils/get-date'
import { creditCardMixin } from '@/mixins/credit-card.mixin'
import IconCreditCards from '../../../components/icons/IconCreditCards.vue'

export default {
  name: 'CardForm',
  props: {
    newCard: Boolean
  },
  mixins: [urlMixin, creditCardMixin],
  components: {
    IconRight,
    CommonModal,
    FixedLengthInput,
    IconCreditCards
  },
  methods: {
    async submitForm () {
      this.setupGmo()
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          let token
          try {
            token = await this.tokenize(
              this.cardHolderName,
              `${this.cardNumber.firstValue}${this.cardNumber.secondValue}${this.cardNumber.thirdValue}${this.cardNumber.fourthValue}`,
              `${this.expirationYear}${String(this.expirationMonth).padStart(2, '0')}`,
              this.securityCode
            )
          } catch {
            this.$toast.error('カード情報の登録に失敗しまいた。入力内容をお確かめ下さい。')
            return
          }
          await this.$showGqlError(async () => {
            await this.$store.dispatch(
              'saveCard',
              token
            )
            this.isSubmitted = true
          })
        })
      }
    }
  },
  data () {
    return {
      cardNumber: {
        firstValue: '',
        secondValue: '',
        thirdValue: '',
        fourthValue: ''
      },
      cardHolderName: '',
      expirationYear: '',
      expirationMonth: '',
      securityCode: '',
      yearList: getNextYearsFromCurrent(new Date().getFullYear(), 10),
      monthList: getMonthList(),
      isSubmitted: false,
      afterSubmitLink: this.getQueryParameter('from') || '/home'
    }
  },
  computed: {
    hasCard () {
      return this.$store.getters.hasValidCard
    }
  }
}
</script>
