<template>
  <div class="form-container bg-w-silver px-5 pb-15">
    <div class="text-title font-weight-bold --prm text-center pt-7 pb-5">
      {{ $t("authPage.needsCreditCard") }}
    </div>
    <div class="form-container px-5 pb-15">
      <card-form new-card />
    </div>
  </div>
</template>

<script>
import CardForm from './CardForm.vue'

export default {
  name: 'NewCardRegister',
  components: {
    CardForm
  }
}
</script>
